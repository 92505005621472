import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_COMPANY = "GET_COMPANY";

export function getCompanyAction(company) {
  return { type: GET_COMPANY, company: company };
}

export const getCompaniesByGroupId = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getCompaniesByGroupId, data)
      .then(function (response) {
        if (response) dispatch(getCompanyAction(response.data));
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
