import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { getCompaniesByGroupId } from "../../actions/company/company";
import { loginAs } from "../../actions/authentication/authentication";
import Util from "../../util/Util";
import APIUrl from "../../APIUrl";
import Notification from "../sub/Notification";

class LoginAsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedClient: null,
      clients: [],
    };
  }

  componentDidMount() {
    this.props.onGetCompaniesByGroupId(
      { group_id: this.props.user.group_id },
      (data) => this.setState({ clients: data }),
    );
  }

  getSelectOptions() {
    if (!this.state.clients || this.state.clients.length === 0) return;

    const options = [];
    const clients = this.state.clients;
    for (const c of clients) {
      options.push({
        value: c._id,
        label: c.name,
      });
    }
    return options;
  }

  redirectToApp() {
    const onLoginFail = (error) => {
      switch (error) {
        case "Network Error":
          Notification({
            type: "error",
            description: this.props.intl.formatMessage({ id: "Network.Error" }),
          });
          break;
        case "EmptyCredentials":
          Notification({
            type: "info",
            description: this.props.intl.formatMessage({
              id: "Login.EmptyCredentials",
            }),
          });
          break;
        case "Too much requests":
          Notification({
            type: "error",
            description: this.props.intl.formatMessage({
              id: "Login.TooMuchRequests",
            }),
          });
          break;
        default:
          Notification({
            type: "error",
            description: this.props.intl.formatMessage({ id: "Login.Fail" }),
          });
          break;
      }
    };

    const onLoginSuccess = (user) => {
      if (
        user.access_pharmamad ||
        user.access_module_home ||
        user.access_module_desk ||
        user.access_module_store
      )
        window.location.replace(
          Util.checkHttps(process.env.REACT_APP_PHARMA_URL) + APIUrl.jwtToken,
        );
    };

    const data = {
      username: this.props.username,
      password: this.props.password,
      company_id: this.state.selectedClient.value,
      token: APIUrl.jwtToken,
    };

    this.props.onLoginAs(data, onLoginSuccess, onLoginFail);
  }

  render() {
    if (!this.state.clients || this.state.clients.length === 0) return;

    return (
      <Modal
        show={true}
        backdrop="static"
        keyboard={false}
        onHide={() => this.props.close()}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-building mr-1"></i>
            <FormattedMessage id="Login.As" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            placeholder={<FormattedMessage id="Select.Pharmacy" />}
            options={this.getSelectOptions()}
            isClearable
            noOptionsMessage={() => <FormattedMessage id={"No.Result"} />}
            onChange={(option) => this.setState({ selectedClient: option })}
            value={this.state.selectedClient}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!this.state.selectedClient}
            variant="info"
            onClick={() => this.redirectToApp()}
          >
            <FormattedMessage id="Confirm" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCompaniesByGroupId: (data, successCallback) =>
      dispatch(getCompaniesByGroupId(data, successCallback)),
    onLoginAs: (data, successCallback, failureCallback) =>
      dispatch(loginAs(data, successCallback, failureCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LoginAsModal));
