import { FormattedMessage } from "react-intl";
import {
  TypeOptions,
  Theme,
  toast,
  ToastPosition,
  ToastTransition,
  Bounce,
} from "react-toastify";

interface NotificationProps {
  type?: TypeOptions;
  title?: string;
  description?: string;
  position?: ToastPosition;
  duration?: number;
  hideProgressBar?: boolean;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  theme?: Theme;
  transition?: ToastTransition;
}

function Notification({
  type = "info",
  title,
  description,
  position = "top-right",
  duration = 5000,
  hideProgressBar = false,
  closeOnClick = true,
  pauseOnHover = true,
  draggable = true,
  theme = "dark",
  transition = Bounce,
}: NotificationProps) {
  const toastFunctions = {
    success: toast.success,
    info: toast.info,
    warning: toast.warning,
    error: toast.error,
    loading: toast.loading,
    default: toast,
  };

  const showToast = toastFunctions[type];
  const titleToast = title ? title : <FormattedMessage id="Notification" />;

  const content = (
    <div>
      <strong>{titleToast}</strong>
      {description && <p>{description}</p>}
    </div>
  );

  return showToast(content, {
    position,
    autoClose: duration,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    theme,
    transition,
  });
}

export default Notification;
